import React, { useContext, useEffect, useState } from "react";
import DashBoardTable from "../../../Components/Dashboard_Table/DashboardTable";
import {
  Box,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import "./Returns.scss";
import MobileTable from "../../../Components/Mobile_Table/MobileTable";
import MobileMenuBar from "../../../Components/Mobile_Menubar/MobileMenubar";
import { useQuery } from "@apollo/client";
import { returnsPageQuery } from "../../../Operations/Queries";
import { CountryContext } from "../../..";
import NoProductFound from "../../../Components/Noproduct/NoProductFound";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Returns = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("Before Delivery");
  const [value, setValue] = useState(0);
  const [beforeDeliveryOrders, setBeforeDeliveryOrders] = useState<any[]>([]);
  const [afterDeliveryOrders, setAfterDeliveryOrders] = useState<any[]>([]);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t }: { t: (key: string) => string } = useTranslation();
  const { store } = useContext(CountryContext);
    const currencyMap : any  = {
    sell_in: { en: "INR", ar: "روبية هندية" },
    sell_sa: { en: "SAR", ar: "ريال سعودي" },
    sell_om: { en: "OMR", ar: "ريال عماني" },
    seller: { en: "AED", ar: "درهم" },
  };
  
  const defaultCurrency = { en: "AED", ar: "درهم" };
  
  const { en: currEn, ar: currAr } = currencyMap[store] || defaultCurrency;
  
  const currency = i18n.language === "ar" ? currAr : currEn;

  const {
    data: returnsBeforeDeliveryData,
    loading: loadingBeforeDeliveryData,
    error: beforeDeliveryError,
  } = useQuery(returnsPageQuery.RETURNS_BEFORE_DELIVERY, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });
  const BeforeDeliveryReturnsData = returnsBeforeDeliveryData?.returnOrders;

  const { data: returnsAfterDeliveryData } = useQuery(
    returnsPageQuery.RETURNS_AFTER_DELIVERY,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );
  const AfterDeliveryReturnsData = returnsAfterDeliveryData?.returnOrders;

  //headCells for the table
  const headCellsForBeforeDelivery = [
    { id: "order", numeric: false, disablePadding: false, label: t("Home_Dashboard.return.order") },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.return.itemName"),
    },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label: `${t("Home_Dashboard.return.itemPrice")}(${currency})`,
    },
    { id: "quantity", numeric: true, disablePadding: false, label: t("Home_Dashboard.return.quantity") },
    {
      id: "itemTotal",
      numeric: true,
      disablePadding: false,
      label: `${t("Home_Dashboard.return.itemTotal")}(${currency})`,
    },
  ];
  const headCellsForAfterDelivery = [
    { id: "order", numeric: false, disablePadding: false, label: t("Home_Dashboard.return.order") },
    {
      id: "itemName",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.return.itemName"),
    },
    {
      id: "itemPrice",
      numeric: true,
      disablePadding: false,
      label: t("Home_Dashboard.return.itemPrice"),
    },
    { id: "quantity", numeric: true, disablePadding: false, label: t("Home_Dashboard.return.quantity") },
    {
      id: "itemTotal",
      numeric: true,
      disablePadding: false,
      label: t("Home_Dashboard.return.itemTotal"),
    },
    {
      id: "returnDate",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.return.returnDate"),
    },
    {
      id: "receivedDate",
      numeric: false,
      disablePadding: false,
      label: t("Home_Dashboard.return.receivedDate"),
    },
  ];

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const menuItems = [t("Home_Dashboard.return.before_delivery"), t("Home_Dashboard.return.after_delivery")];

  const handleMenuItem = (itemName: any) => {
    setSelectedMenuItem(itemName);
  };

  const getTableHeadCells = (menuItem: string) => {
    switch (menuItem) {
      case "Before Delivery":
        return headCellsForBeforeDelivery;
      case "After Delivery":
        return headCellsForAfterDelivery;
    }
  };

  const getTableRows = (menuItem: string) => {
    switch (menuItem) {
      case "Before Delivery":
        return beforeDeliveryOrders;
      case "After Delivery":
        return afterDeliveryOrders;
    }
  };

  useEffect(() => {
    const transformed = BeforeDeliveryReturnsData?.map(
      (order: any, index: any) => ({
        id: index,
        order: order.order_number,
        itemName: order.product_name,
        itemPrice: order.price,
        quantity: order.qty,
        itemTotal: order.sub_total,
      })
    );
    setBeforeDeliveryOrders(transformed);
  }, [BeforeDeliveryReturnsData]);

  useEffect(() => {
    const transformed = AfterDeliveryReturnsData?.map(
      (order: any, index: any) => ({
        id: index,
        order: order.order_number,
        itemName: order.product_name,
        itemPrice: order.price,
        quantity: order.qty,
        itemTotal: order.sub_total,
        returnDate: order.return_date,
        receivedDate: order.received_date,
      })
    );
    setAfterDeliveryOrders(transformed);
  }, [AfterDeliveryReturnsData]);

  const errorBeforeDeliveryReturns = beforeDeliveryError?.message;

  return (
    <div className="returns-page">
      <MobileMenuBar
        menuItems={menuItems}
        onItemClick={handleMenuItem}
        selectedMenuItem={selectedMenuItem}
      />
      <Box sx={{ width: "100%" }} className="orders-tabs mobile__view__none">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={7}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="order-tabs-head"
              >
                <Tab
                  label={t("Home_Dashboard.return.before_delivery")}
                  {...a11yProps(0)}
                  className="tabs-title"
                />
                <Tab
                  label={t("Home_Dashboard.return.after_delivery")}
                  {...a11yProps(1)}
                  className="tabs-title"
                />
              </Tabs>
            </Box>
          </Grid>
        </Grid>
        {beforeDeliveryError ? (
          <p className="error-text">{errorBeforeDeliveryReturns}</p>
        ) : loadingBeforeDeliveryData ? (
          <CircularProgress className="loader" />
        ) : (
          <>
            <CustomTabPanel value={value} index={0}>
              {beforeDeliveryOrders && beforeDeliveryOrders.length > 0 ? (
                <DashBoardTable
                  headCells={headCellsForBeforeDelivery}
                  rows={beforeDeliveryOrders}
                />
              ) : (
                <NoProductFound />
              )}
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              {afterDeliveryOrders && afterDeliveryOrders.length > 0 ? (
                <DashBoardTable
                  headCells={headCellsForAfterDelivery}
                  rows={afterDeliveryOrders}
                />
              ) : (
                <NoProductFound />
              )}
            </CustomTabPanel>
          </>
        )}
      </Box>
      <Box className="mobile__view__block">
        {menuItems.map(
          (menuItem, index) =>
            selectedMenuItem === menuItem && (
              // <MobileTable key={index} headCells={getTableHeadCells(menuItem)} rows={getTableRows(menuItem)} />
              <>
                {beforeDeliveryError ? (
                  <p className="error-text">{errorBeforeDeliveryReturns}</p>
                ) : loadingBeforeDeliveryData ? (
                  <CircularProgress className="loader" />
                ) : (
                  <MobileTable
                    key={index}
                    headCells={getTableHeadCells(menuItem)}
                    rows={getTableRows(menuItem)}
                  />
                )}
                {/* <div className='loader'>
                      <CircularProgress className='loader' />
                      </div> */}
              </>
            )
        )}
      </Box>
    </div>
  );
};

export default Returns;
